

































































import Vue from "vue";
import Component from "vue-class-component";

import { Locations, Auth } from "@/store/modules";
import { Location } from "@/store/locations";

import SelectReusables from "@/components/SelectReusables.vue";
import { User } from "@/store/auth";

@Component({
  components: {
    SelectReusables,
  },
})
export default class TakeContainer extends Vue {
  isLoading = false;
  loadingError = "";
  isLoadingTake = false;
  takeOver = false;
  takeError = "";
  location: any = {};
  containers: any[] = [];
  accessories: any[] = [];
  myContainersCount = 0;
  myAccessoriesCount = 0;
  stopConfettiTimeout: any;
  selectPlan = false;
  userContainers = 0;

  get vendor() {
    return this.$route.query.vendor;
  }

  get noContainers() {
    return this.containers.length === 0;
  }

  get noAccessories() {
    return this.accessories.length === 0;
  }

  get selectedContainersAmount() {
    return this.containers.reduce((agg: number, cur: any) => {
      return agg + cur.selected;
    }, 0);
  }

  get containersAmount() {
    return this.selectedContainersAmount + this.myContainersCount;
  }

  get selectedAccessoriesAmount() {
    return this.accessories.reduce((agg: number, cur: any) => {
      return agg + cur.selected;
    }, 0);
  }

  get accessoriesAmount() {
    return this.selectedAccessoriesAmount + this.myAccessoriesCount;
  }

  get totalAmount() {
    return this.selectedAccessoriesAmount + this.selectedContainersAmount;
  }

  get totalWithUserAmount() {
    return this.totalAmount + this.userContainers;
  }

  get containerTypes() {
    return Locations.containerTypes;
  }

  get reusables() {
    return [
      ...this.containers
        .map((container: any) => ({
          id: container.type,
          amount: container.selected,
        })),
      ...this.accessories
        .map((accessory: any) => ({
          id: accessory.type,
          amount: accessory.selected,
        })),
    ];
  }

  async take() {
    this.isLoadingTake = true;
    try {
      // await Auth.takeContainer({ locationId: this.vendor as string, containerId: this.container.id, subscriptionId: sub._id, amount: this.amount });
      await Auth.takeContainers({
        locationId: this.vendor as string,
        reusables: this.reusables,
      });
      setTimeout(() => {
        (this as any).$confetti.start();
      }, 500);
      this.stopConfettiTimeout = setTimeout(() => {
        (this as any).$confetti.stop();
      }, 60000);
    } catch (err) {
      this.takeError = err.message;
    }
    this.isLoadingTake = false;
    this.takeOver = true;
  }

  beforeDestroy() {
    clearTimeout(this.stopConfettiTimeout);
    (this as any).$confetti.stop();
  }

  async refresh() {
    this.isLoading = true;
    try {
      await Locations.fetchAll();
      const fetchedLocation = await Locations.fetchOne({
        slug: this.vendor as string,
      });
      this.location = Object.assign({}, fetchedLocation);
      await Auth.refreshAccountData();
      if (!(Auth.user as User).paymentsEnabled) {
        this.$router.push('/setup-account');
      }
      this.userContainers = (Auth.user as User).activeReusables;
      const { containers } = fetchedLocation;
      const allReusables = Object.keys(containers).map((index) => ({
        type: containers[index].id,
        available: containers[index].availableCount,
        selected: 0,
      }));

      const filterReusables = (
        reusablesToFilter: any[],
        isContainer: boolean
      ) => {
        return reusablesToFilter.filter((reusable: any) => {
          const foundType = this.containerTypes.find(
            (type) => reusable.type === type.id
          );
          return (
            foundType &&
            (isContainer ? foundType.isContainer : !foundType.isContainer)
          );
        });
      };

      this.containers = this.containerTypes.filter(t => t.isContainer).map((t) => {
        const found = allReusables.find(r => r.type === t.id);

        return {
          ...found,
          available: found ? found.available : 0,
          selected: found ? found.selected : 0,
          type: found ? found.type : t.id
        }
      });

      this.accessories = this.containerTypes.filter(t => !t.isContainer).map((t) => {
        const found = allReusables.find(r => r.type === t.id);

        return {
          ...found,
          available: found ? found.available : 0,
          selected: found ? found.selected : 0,
          type: found ? found.type : t.id
        }
      });

      const myReusables = (await Auth.myContainers()).containers;
      const myReusablesFormatted = Object.keys(myReusables).map((key) => {
        return {
          type: key,
          amount: myReusables[key],
        };
      });
      const myContainers = filterReusables(myReusablesFormatted, true);
      const myAccessories = filterReusables(myReusablesFormatted, false);
      this.myContainersCount = myContainers.reduce((agg: number, cur: any) => {
        return agg + cur.amount;
      }, 0);
      this.myAccessoriesCount = myAccessories.reduce(
        (agg: number, cur: any) => {
          return agg + cur.amount;
        },
        0
      );
    } catch (err) {
      this.loadingError = err.message;
    }
    this.isLoading = false;
  }

  getContainerData(id: string) {
    return (
      this.containerTypes.find((containerType) => containerType.id === id) || {
        name: "Unknown container",
        size: "Unknown size",
      }
    );
  }

  resetContainers() {
    this.containers.forEach((container: any) => {
      container.selected = 0;
    });
  }

  mounted() {
    this.refresh();
  }
}
