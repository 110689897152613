























import { Vue, Component, Prop } from "vue-property-decorator";

import { Locations } from '@/store/modules';

@Component
export default class SelectReusables extends Vue {
  @Prop() reusables!: any[];

  get containerTypes() {
    return Locations.containerTypes;
  }

  getContainerData(id: string) {
    return this.containerTypes.find(containerType => containerType.id === id) || { name: 'Unknown container', size: 'Unknown size' };
  }

  validate(reusable: any) {
    if (reusable.selected > reusable.available) {
      reusable.selected = reusable.available;
    }

    if (reusable.selected < 0) {
      reusable.selected = 0;
    }
  }
}
